@import "../../../../../variables";
.aw-input {

  .header .metadata > * {
    margin: 0 .2rem;
  }

  .content-title {
    .aw-icon {
      font-size: 14px;
      vertical-align: top;
      +.aw-icon {
        margin-left: calc(#{$spacer} / 4);
      }
    }
  }

  .longText{
    font-size: 8px !important;
  }
  .thumbnail .thumb-warning,
  .thumbnail .thumb-error {
    text-align: center;
    font-size: 14px !important;
  }

  .warning_hoover .thumb-warning-tooltip {
    color: $color-gray-05;
    position: absolute;
    background-color: rgba($color-yellow-01, .8);
    height:100px !important;
    z-index:1;
    height: 100%;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    display: none;
    }

    .warning_hoover:hover .thumb-warning-tooltip{
      display: flex;
    }


  .bitrate-indicator {
    color: $color-dark-text-primary;
    font-size: 1.5rem;
  }

  .bitrate-badge {
    bottom: 28px !important;
    color: $color-dark-text-primary;
    background-color: rgba($color-gray-05, .8);
    border-color: rgba($color-gray-05, .8);
  }

  .live-timer {
    color: $color-dark-text-primary;
    font-size: $font-size-base;
    line-height: 1.5rem;
    align-self: baseline;
  }

  .battery-level {
    flex: 0 1 auto;
    margin-left: 0.5rem;
    display: flex;
    font-size: $font-size-discreet;
  }

  .aw-control-grid.inline &.aw-control-grid-item.collapsed {
    .header .controls {
      width: 100%;
    }
    .content .main-content .compact {
      padding-left: $spacer;
    }
  }

  .playback {
    .thumbnail:hover .progress {
      height: 7px;
    }

    .select > button {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .overlay{
      height: 33px !important;
    }
  }

  &.aw-control-grid-item .main-content {
    .compact .actions {
      button + .copy-to-clipboard {
        margin-left: 6px;
      }
      .copy-to-clipboard + button {
        margin-left: 6px;
      }
    }

    .details {
      margin-bottom: .25rem;
    }
  }

  .nav {
    margin-bottom: 0;
  }

  .additional-content {
    .controls {
      display: flex;
      align-items: center;
      button {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
      .aw-circular-badge {
        padding: 0.4rem;
      }

      .graph-legend {
        white-space: nowrap;
      }

      .graph-legend {
        margin-right: .5rem;
      }
    }
    .background{
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 2px solid $color-dark-bg-box;
    }

    .rounded {
      border-radius: 8px !important;
    }
  }

  $module-padding: 1rem 0 0 0;

  .live-info {
    flex: 0 0 240px;
    text-align: center;
    margin-left: auto;
    .row {
      line-height: 0.8;
    }

    dl {
      @include aw-theme(background-color, $color-dark-bg-box, $color-light-bg-box);
      margin-bottom: 0;
      padding: .5rem 0 0 0;
    }

    dt {
      text-align: right;
      font-weight: normal;

      #{$theme-dark} & {
        color: $color-dark-text-secondary;
      }

      #{$theme-light} & {
        color: $color-light-text-secondary;
      }

    }
    dd {
      text-align: left;
    }

    dt,
    dd {
      padding: 0 0.3rem;
    }
  }


  .settings-controls {
    flex: 0 1 280px;
    margin-left: 1em;
    margin-right: 1em;
    width: 280px;

    .controls {
      @include aw-theme($color-dark-bg-box, $color-light-bg-box);

      .latency-controls, .bitrate-controls {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        font-size: 0.9rem;

        .form-inline {
          width: 100%;
        }
        .form-control {
          background-color: $color-dark-bg-box;
        }
        .rc-slider-rail {
          background-color: $color-dark-bg-box;
        }
        .manual {
          flex: 1 0 auto;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          button {
            margin: 0
          }
        }
      }

      .max-value,
      .min-value {
        font-size: $font-size-discreet;

        #{$theme-dark} & {
          color: $color-dark-text-secondary;
        }

        #{$theme-light} & {
          color: $color-light-text-secondary;
        }
      }

      .max-value {
        float: right;
      }
    }
  }

  .stream-info {
    position: absolute;
    bottom: calc(#{$spacer} * 2);
    left: calc(#{$spacer} * 4);
    margin-bottom: 1em;
    opacity: 0.7;
    @include aw-theme(background-color, $color-dark-bg-box, $color-light-bg-box);
    .title {
      text-align: center;

      button {
        float: right;
      }
    }

    table {

      @include aw-theme($color-dark-bg-box, $color-light-bg-box);
      margin-bottom: 0;
      margin-top: 0;
      text-align: center;
      th {
        vertical-align: middle;
      }
      tbody th {
        font-weight: 600;
        font-size: $font-size-discreet;
        text-align: right;

        #{$theme-dark} & {
          color: $color-dark-text-secondary;
        }

        #{$theme-light} & {
          color: $color-light-text-secondary;
        }
      }

      td.bitrate {
        min-width: 80px;
      }
    }
  }

  .stream-info-device {
    opacity: 0.7;
    @include aw-theme(background-color, $color-dark-bg-box, $color-light-bg-box);
    .title {
      text-align: center;

      button {
        float: right;
      }
    }

    table {

      @include aw-theme($color-dark-bg-box, $color-light-bg-box);
      margin-bottom: 0;
      margin-top: 0;
      text-align: center;
      font-size: $font-size-discreet;

      th {
        vertical-align: middle;
      }
      tbody th {
        font-weight: 600;
        text-align: right;

        #{$theme-dark} & {
          color: $color-dark-text-secondary;
        }

        #{$theme-light} & {
          color: $color-light-text-secondary;
        }
      }

      td.bitrate {
        min-width: 80px;
      }
    }
  }

  .btn-group > .btn:not(:last-child):not(.dropdown-toggle).remote-profiles-toggle {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  .network-info {
    .title {
      text-align: center;
    }
    .content {
      @include aw-theme(background-color, $color-dark-bg-box, $color-light-bg-box);
      padding: .5rem;
    }
    sup {
      display:block; position:relative; left:17px; top: -12px
    }
    sub {
      display:block; position:relative; left:17px; top:0px
    }
    .aw-icon {
      font-size: 1.4em
    }
    .subtitle {
      font-size: $font-size-discreet;
    }
    table {
      margin-bottom: 0;
      th {
        font-weight: 600;
        font-size: $font-size-discreet;
        text-align: center;

        #{$theme-dark} & {
          color: $color-dark-text-secondary;
        }

        #{$theme-light} & {
          color: $color-light-text-secondary;
        }

      }
      td {
        text-align: center;
        font-size: $font-size-discreet;
      }

      td.bitrate {
        min-width: 80px;
      }
    }
  }

  .aw-loader .scale-loader [class*="bar"] {
    height: 32px;
  }
}

.meta-data {
  width: 400px;
  font-size: 0.8rem;
  dl {
    width: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0 0.5em 0 0.5em;
  }
  dt {
    float: left;
    clear: left;
    width: 40%;
    font-weight: bold;
    color: $color-gray-02;
  }

  dt::after {
    content: " :";
  }
  dd:empty::after {
    content: "-";
  }
}

.graph-container-fullsize {
  height: 90%;
  min-height: 120px;
}
.graph-container-1 {
  height: 81.8%;
  min-height: 120px;
}
.graph-container-2 {
  height: 38%;
  min-height: 120px;
}
.graph-container-3 {
  height: 24%;
  min-height: 120px;
}
.graph-container-4 {
  height: 24%;
  min-height: 120px;
}

.graph-container-1-live {
  height: 75%;
  min-height: 120px;
}
.graph-container-2-live {
  height: 35%;
  min-height: 120px;
}
.graph-container-3-live {
  height: 21%;
  min-height: 100px;
}
.graph-container-4-live {
  height: 21%;
  min-height: 100px;
}